@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: 'Montserrat', sans-serif; }

.App {
  position: relative;
  height: 100vh;
  text-align: center; }

.App-logo {
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.heading-small {
  display: none; }

.bp3-navbar {
  position: fixed !important; }

.App-link {
  color: #09d3ac; }

.main-content {
  padding-top: 50px;
  padding-bottom: 30px; }

.copywrite {
  position: fixed;
  bottom: 0px;
  text-align: center;
  width: 100%;
  background: white;
  height: 25px;
  vertical-align: middle;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  display: flex;
  align-items: center;
  justify-content: center; }

img {
  border-radius: 7px; }

/* Home */
.home {
  padding: 15px; }

.intro {
  text-align: left;
  width: 75%;
  margin: 15px auto; }

.home .cards {
  display: flex;
  padding-top: 15px;
  text-align: left; }

.bp3-card {
  flex: 1 1;
  margin: 15px; }

/* About */
.about {
  width: 800px;
  font-size: 18px;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: left; }

.about div {
  margin: 25px; }

/* Arcade */
.arcade {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left; }
  .arcade .overview img {
    width: 400px; }
  .arcade .sub {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    padding: 15px;
    margin: auto;
    height: 100%; }
    .arcade .sub img {
      width: 300px;
      margin: 15px; }

.arcade .bp3-card {
  width: 75%; }

.arcade .images {
  text-align: center;
  margin: 10px; }

.arcade
.arcade .sub > * {
  margin: 20px; }

.arcade img {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px; }

/* Automotive */
table, tr, td {
  border: none; }

.automotive {
  padding: 15px; }

.automotive .col {
  display: flex; }

.custom-trunk, .custom-corners {
  text-align: left; }
  .custom-trunk p, .custom-corners p {
    padding: 5px; }
  .custom-trunk img, .custom-corners img {
    margin: 10px; }

.car-images {
  display: flex;
  flex-direction: column;
  width: 250px; }
  .car-images img {
    margin: 5px 0px; }

/* Development */
.development {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left; }
  .development .login-practice img {
    width: 600px; }
  .development .dev-center {
    text-align: center; }

.development .carshowz-images > img:first-child {
  border-radius: 10px; }

.development .carshowz-images img {
  width: 25%;
  margin: 15px; }

.development .rttl img {
  width: 450px; }

.development img {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  margin: 15px; }

.development-section {
  width: 75%; }

/* Random */
.random {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .random img {
    margin: 15px; }
  .random .perler img {
    height: 300px; }
    .random .perler img:last-child {
      width: 250px; }
  .random .bar img {
    height: 400px; }
    .random .bar img:last-child {
      width: 350px; }

/* Videos */
.videos {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center; }

@media only screen and (max-width: 900px) {
  .arcade .bp3-card, .random .bp3-card, .development .bp3-card {
    width: 100%; }
  .arcade .sub {
    display: block; }
  .bp3-navbar .bp3-button-text {
    display: none; }
  .heading-large {
    display: none; }
  .heading-small {
    display: block; }
  img {
    width: 100% !important;
    margin: 15px 0 !important; }
  .about {
    width: 100%;
    margin-bottom: 50px;
    top: 50px; }
  .home .cards {
    display: block; } }

@media only screen and (max-width: 600px) {
  .bp3-navbar-heading {
    margin-right: 0px !important; }
  .automotive .col {
    display: block; }
  .automotive .col .bp3-card {
    width: 100%;
    margin: 15px 0; } }

